export default {
  data() {
    return {

      fields: [
        { key: "title", label: "اسم" },
        { key: "duration", label: "المدة" },
        { key: "actions", label: "" }
      ],
      estatesFormSchemaPoints: {
        long: {
          key: "long",
        },
      },
      estatesFormSchema: {
        title: {
          component: "FormInput",
          key: "title",
          attrs: {
            id: "title",
            label: "اسم",
            rules: "required",
          },
        },
        duration: {
          component: "FormInput",
          key: "duration",
          attrs: {
            id: "duration",
            label: "المدة",
            rules: "required",
          },
        },
        tour_categories_ids: {
          component: "FormSelect",
          key: "tour_categories_ids",
          attrs: {
            reduce: (x) => x.id,
            getOptionLabel: (x) => x.name,
            id: "tour_categories_ids",
            label: "التصنيفات",
            ep: "select/tour-categories",
            rules: "required",
            multiple: true
          },
        },
        description: {
          component: "FormTextarea",
          key: "description",
          attrs: {
            label: "التفاصيل",
            rules: "required",
          },
        },
        long: {
          key: "long",
        },
        image: {
          key: "image",
        },
      }
    };
  },
}